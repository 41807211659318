import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v2/api/uploads`);
        const files =  response.data;

        console.log('Files:', files);

        // Format the images to include full URL
        const formattedImages = files.map((file) => ({
          src: `${apiUrl}/api/v2/api/uploads/${file}`,
          alt: file,
        }));

        setImages(formattedImages);
      } catch (error) {
        console.log(`Error fetching images: ${error}`);
      }
    };

    fetchImages();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <section className="py-16 bg-gradient-to-r from-gray-100 to-gray-200">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-extrabold text-center mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Wheels of Choice
          <motion.div
            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2 mx-auto w-24"
            layoutId="underline"
            initial={{ width: 0 }}
            animate={{ width: '20%' }}
            transition={{ duration: 0.5 }}
          />
        </motion.h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <motion.div
              key={index}
              className="relative rounded-lg overflow-hidden shadow-lg cursor-pointer"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              onClick={() => handleImageClick(image)}
            >
              <motion.img
                src={image.src}
                alt={image.alt}
                className="object-cover h-[200px] md:h-[300px] w-full"
                initial={{ scale: 1.1 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.6, ease: 'easeInOut' }}
              />
              <motion.div
                className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300"
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <motion.p
                  className="text-white text-lg font-bold"
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  Click to view
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </div>

        {/* Modal for full-size image */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleCloseModal} // Close the modal when clicking on the background
            >
              <motion.div
                className="relative"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.8 }}
                onClick={(e) => e.stopPropagation()} // Prevent background click from closing the modal
              >
                <motion.img
                  src={selectedImage.src}
                  alt={selectedImage.alt}
                  className="max-h-[80vh] max-w-[90vw] rounded-lg shadow-lg"
                />
                {/* <button
                  className="absolute top-4 right-4 text-white bg-yellow-500 rounded-[200px] p-[5px] focus:outline-none"
                  onClick={handleCloseModal}
                >
                  X
                </button> */}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Gallery;
