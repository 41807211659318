import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaCloudUploadAlt, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';


const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const response = await axios.post(`${apiUrl}/api/v2/api/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 5 * 20 * 3000,
      });
      console.log('Image upload response 01:', response);
      console.log('Image upload response 02:', response.data);
      const data =  response.data;
      setUploadMessage(data.message);
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadMessage('Image upload failed.');
    }
  };

  return (
    <div className="p-8 max-w-lg mx-auto bg-white shadow-lg rounded-lg mt-[100px] mb-[100px]">
      <motion.h2
        className="text-2xl font-bold mb-6 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Admin Panel - Upload Images
      </motion.h2>

      <div className="mb-4">
        <input
          type="file"
          onChange={handleFileChange}
          className="border p-2 w-full rounded-md"
        />
      </div>

      <motion.button
        className="bg-blue-500 text-white p-2 rounded-lg w-full"
        onClick={handleUpload}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Upload Image
      </motion.button>

      {uploadMessage && (
        <motion.p
          className="mt-4 text-center text-green-500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {uploadMessage}
        </motion.p>
      )}
    </div>
  );
};


export default ImageUpload;
