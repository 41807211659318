import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const PaymentResult = () => {
  const location = useLocation();
  const [transactionData, setTransactionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const mur = searchParams.get('mur');
    const tr = searchParams.get('tr');

    const fetchTransactionData = async () => {
      try {
        const response = await axios.post(`${apiUrl}/api/v2/api/payment-status`, { mur, tr }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Transaction data:', response);
        if (response.status === 200 && response.data) {
            setTransactionData(response.data.gatewayResponse);
        } else {
          setErrorMessage(response.message || 'Transaction retrieval failed');
        }
      } catch (error) {
        console.error('Error fetching transaction:', error);
        setErrorMessage('Error retrieving transaction data');
      }
    };

    fetchTransactionData();
  }, [location.search]);

  if (errorMessage) {
    return <div className="text-red-500 font-semibold text-center mt-5">{errorMessage}</div>;
  }

  if (!transactionData) {
    return <div className="text-gray-700 font-medium text-center mt-5">Loading transaction details...</div>;
  }

  // Display transaction summary details
  const {
    merchant,
    order,
    result,
    sourceOfFunds,
    timeOfRecord,
    transaction
  } = transactionData;

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="transaction-summary bg-white shadow-lg rounded-lg p-8 max-w-lg w-full text-gray-800"
      >
        <h2 className="text-2xl font-semibold text-center mb-4">Transaction Summary</h2>
        <div className="space-y-4">
          <p><strong>Merchant:</strong> {merchant}</p>
          <p><strong>Order ID:</strong> {order.id}</p>
          <p><strong>Amount:</strong> {order.amount} {order.currency}</p>
          <p><strong>Transaction Result:</strong> {result}</p>
          <p><strong>Card Type:</strong> {sourceOfFunds.provided.card.scheme}</p>
          <p><strong>Card Number:</strong> {sourceOfFunds.provided.card.number}</p>
          <p><strong>Transaction Date:</strong> {new Date(timeOfRecord).toLocaleString()}</p>
          <p><strong>Authorization Code:</strong> {transaction.authorizationCode}</p>
          <p><strong>Receipt Number:</strong> {transaction.receipt}</p>
          <p><strong>Status:</strong> {order.status}</p>
          <p><strong>Processing Code:</strong> {transaction.stan}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default PaymentResult;
