import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

function BookingForm() {
  const [bookingDate, setBookingDate] = useState('');
  const [dropOffDate, setDropOffDate] = useState('');
  const [vehicleType, setVehicleType] = useState('Scooter Honda Dio 110cc');
  const [numVehicles, setNumVehicles] = useState(1);
  const [totalPrice, setTotalPrice] = useState(10);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [VehicleavailabilityMessage, setVehicleAvailabilityMessage] = useState('');
  const [isAvailable, setIsAvailable] = useState(true);
  const [isVehicleAvailable, setvehicleIsAvailable] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [selectedNumber, setSelectedNumber] = useState(0);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  // Validation error state
  const [errors, setErrors] = useState({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    bookingDate: '',
    dropOffDate: '',
    numVehicles: '',
  });

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 2);
    return date.toISOString().split('T')[0];
  };

  const calculateTotalPrice = (reservationDate, dropOffDate, type, numVehicles) => {
    if (reservationDate && dropOffDate) {
      const startDate = new Date(reservationDate);
      const endDate = new Date(dropOffDate);

      // Calculate the difference in days
      let dayDifference = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

      // Ensure that we charge for at least one day
      if (dayDifference <= 0) {
        dayDifference = 1;
      }

      let dailyRate;
      switch (type) {
        case 'Jeep':
          dailyRate = 99.99;
          break;
        case 'FordCab':
          dailyRate = 449.99;
          break;
        case 'DFSK Glory':
          dailyRate = 449.99;
          break;
        case 'chevroletCar':
          dailyRate = 99.99;
          break;
        case 'Scooter TVS Ntoq 125cc':
          dailyRate = 9.99;
          break;
        case 'Scooter Honda Dio 110cc':
        default:
          dailyRate = 9.99;
          break;
      }

      return dailyRate * dayDifference * numVehicles;
    }
    return 0;
  };

  const validateVehicleCount = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/v2/api/validate-vehicle-selection`, {
        vehicleType,
        selectedNumber: numVehicles,
      });
      console.log("response", response);
      if (response.status === 200) {
        setvehicleIsAvailable(true);
        setVehicleAvailabilityMessage('');
      } else if (response.status === 201) {
        setvehicleIsAvailable(false);
        setVehicleAvailabilityMessage(response.data.message);
      }
    } catch (error) {
      setvehicleIsAvailable(false);
      setVehicleAvailabilityMessage(error.message || 'Error validating vehicle selection.');
    }
  };

  useEffect(() => {
    // Only validate when numVehicles is greater than 0
    if (numVehicles > 0) validateVehicleCount();
  }, [vehicleType, numVehicles]);

  // Function to check availability from the server
  const checkAvailability = async (vehicleType, bookingDate, dropOffDate, numVehicles) => {
    console.log("vehicle details", vehicleType, bookingDate, dropOffDate, numVehicles);
    if (vehicleType && bookingDate && dropOffDate) {
      try {

        const response = await axios.post(`${apiUrl}/api/v2/api/check-availability`, {
          vehicleType,
          bookingDate,
          dropOffDate,
          numVehicles,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.data.available) {
          setIsAvailable(true);
          setAvailabilityMessage('');
        } else if (response.data.available === false) {
          setIsAvailable(false);
          setAvailabilityMessage(response.message || 'Not enough vehicles available.');
        }
      } catch (error) {
        setIsAvailable(false);
        setAvailabilityMessage(error.message || 'Not enough vehicles available.');
      }
    }
  };

  const validateFields = () => {
    let newErrors = {};

    if (!customerName) {
      newErrors.customerName = 'Please enter your name.';
    }
    if (!customerEmail) {
      newErrors.customerEmail = 'Please enter your email.';
    } else if (!/\S+@\S+\.\S+/.test(customerEmail)) {
      newErrors.customerEmail = 'Please enter a valid email address.';
    }
    if (!customerPhone) {
      newErrors.customerPhone = 'Please enter your phone number.';
    } else if (!/^\d{10,15}$/.test(customerPhone)) {
      newErrors.customerPhone = 'Please enter a valid phone number.';
    }
    if (!bookingDate) {
      newErrors.bookingDate = 'Please select a booking date.';
    }
    if (!dropOffDate) {
      newErrors.dropOffDate = 'Please select a drop-off date.';
    }
    if (numVehicles < 1) {
      newErrors.numVehicles = 'Please select at least one vehicle.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const confirmBooking = async () => {
    if (!validateFields()) {
      return;
    }

    if (!isVehicleAvailable) {
      console.log("Vehicle not available");
      return;
    }

    const bookingDetails = {
      name: customerName,
      email: customerEmail,
      phone: customerPhone,
      vehicleType,
      bookingDate,
      dropOffDate,
      numVehicles,
      totalPrice,
    };

    try {
      const response = await axios.post(`${apiUrl}/api/v2/api/create-payment`, bookingDetails, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.data.success) {
        window.location.href = response.data.payUrl;
      } else {
        alert(response.message || 'Error initiating payment.');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  // Update total price whenever relevant inputs change
  useEffect(() => {
    const price = calculateTotalPrice(bookingDate, dropOffDate, vehicleType, numVehicles);
    setTotalPrice(price);
  }, [bookingDate, dropOffDate, vehicleType, numVehicles]);

  // Check availability when inputs change
  useEffect(() => {
    if (vehicleType && bookingDate && dropOffDate) {
      checkAvailability(vehicleType, bookingDate, dropOffDate, numVehicles);
    }
  }, [vehicleType, bookingDate, dropOffDate, numVehicles]);

  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-lg shadow-lg">
      <div className="text-center mb-4">
        {/* <img
          src="logo/Logo.png"
          alt="Ella Scooter Rent Logo"
          className="mx-auto mb-2 h-[170px] w-auto"
        /> */}
        <h2 className="text-black text-2xl font-bold relative bottom-7">Ella Scooter Rent</h2>
      </div>

      {/* Your name */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Your name</label>
        <input
          type="text"
          placeholder="Your name"
          className={`w-full p-2 border rounded ${errors.customerName ? 'border-red-500' : ''}`}
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
        {errors.customerName && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.customerName}
          </motion.p>
        )}
      </div>

      {/* Email field */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Email</label>
        <input
          type="email"
          placeholder="Email"
          className={`w-full p-2 border rounded ${errors.customerEmail ? 'border-red-500' : ''}`}
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
        />
        {errors.customerEmail && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.customerEmail}
          </motion.p>
        )}
      </div>

      {/* Phone number */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Phone number</label>
        <input
          type="tel"
          placeholder="Phone number"
          className={`w-full p-2 border rounded ${errors.customerPhone ? 'border-red-500' : ''}`}
          value={customerPhone}
          onChange={(e) => setCustomerPhone(e.target.value)}
        />
        {errors.customerPhone && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.customerPhone}
          </motion.p>
        )}
      </div>

      {/* Vehicle type */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Vehicle type</label>
        <select
          className="w-full p-2 border rounded"
          value={vehicleType}
          onChange={(e) => setVehicleType(e.target.value)}
        >
          <option value="Scooter Honda Dio 110cc">Scooter Honda Dio 110cc - ($9.99) per day</option>
          <option value="Jeep">Jeep - ($99.99) per day</option>
          <option value="FordCab">Ford Cab - ($449.99) per day</option>
          <option value="DFSK Glory">DFSK Glory - ($449.99) per day</option>
          <option value="chevroletCar">Chevrolet Car - ($99.99) per day</option>
          <option value="Scooter TVS Ntoq 125cc">Scooter TVS Ntoq 125cc - ($9.99) per day</option>
        </select>
      </div>

      {/* Number of vehicles */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Number of vehicles</label>
        <input
          type="tel"
          className={`w-full p-2 border rounded ${errors.numVehicles ? 'border-red-500' : ''}`}
          value={numVehicles || ''}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            setNumVehicles(isNaN(value) ? "" : value); // Set empty if not a number
          }}
          placeholder="Enter number of vehicles"
        />
        {errors.numVehicles && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.numVehicles}
          </motion.p>
        )}
      </div>
      {VehicleavailabilityMessage && (
        <p className="text-red-500 text-sm mt-1">
          {VehicleavailabilityMessage}
        </p>
      )}

      {/* Reservation date */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Reservation date</label>
        <input
          type="date"
          className={`w-full p-2 border rounded ${errors.bookingDate ? 'border-red-500' : ''}`}
          min={getMinDate()}
          value={bookingDate}
          onChange={(e) => setBookingDate(e.target.value)}
        />
        {errors.bookingDate && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.bookingDate}
          </motion.p>
        )}
      </div>

      {/* Drop off date */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Drop off date</label>
        <input
          type="date"
          className={`w-full p-2 border rounded ${errors.dropOffDate ? 'border-red-500' : ''}`}
          min={bookingDate || getMinDate()}
          value={dropOffDate}
          onChange={(e) => setDropOffDate(e.target.value)}
        />
        {errors.dropOffDate && (
          <motion.p className="text-red-500 text-sm mt-1" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {errors.dropOffDate}
          </motion.p>
        )}
      </div>

      {/* Availability Message */}
      {availabilityMessage && <p className="text-red-500">{availabilityMessage}</p>}

      {/* Total Due */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Total due</label>
        <p className="text-gray-900">${totalPrice.toFixed(2)}</p>
      </div>

      {/* Pay button */}
      <button
        className="w-full bg-red-500 text-white p-2 rounded-full"
        disabled={!isAvailable}
        onClick={confirmBooking}
      >
        Pay ${totalPrice.toFixed(2)}
      </button>

      <p className="text-center text-sm text-gray-600 mt-4">Safe and secure payments SSL encrypted</p>
    </div>
  );
}

export default BookingForm;
